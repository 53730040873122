import React, { useRef, useState } from 'react'; 
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const userName = formData.get('user_name');
    const userEmail = formData.get('user_email');
    const userPhone = formData.get('user_phone');
    const message = formData.get('message');

    // Validation
    if (!userName || !userEmail || !userPhone || !message) {
      setStatusMessage('All fields are required.');
      return;
    }

    const emailData = {
      from_name: userName,
      reply_to: userEmail,
      user_phone: userPhone,
      message: message,
      to_name: "Harut",
    };

    emailjs
      .send(
        'service_wszckve',
        'template_75wpo3x',
        emailData,
        'PQwZpkukfTfGWmWEP'
      )
      .then((result) => {
        console.log(result.text);
        setStatusMessage('Email sent successfully!');
        form.current.reset(); // Clear form after success
      })
      .catch((error) => {
        console.log(error.text);
        setStatusMessage('Failed to send email. Please try again.');
      });
  };

  return (
    <div className="StyledContactForm">
      <div className="contacttext">
        <h1>
          Get in <span>Touch</span>
        </h1>
        <p>
          We welcome inquiries about new projects, old projects, and more. Please
          call us for an inquiry or a quote. We check our email seven days a
          week, so that is another efficient way to contact us.
        </p>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <label htmlFor="user_name"></label>
        <input id="user_name" type="text" name="user_name" placeholder="Name" required />

        <label htmlFor="user_email"></label>
        <input id="user_email" type="email" name="user_email" placeholder="Email" required />

        <label htmlFor="user_phone"></label>
        <input id="user_phone" type="tel" name="user_phone" placeholder="Phone Number" required />

        <label htmlFor="message"></label>
        <textarea id="message" name="message" placeholder="How did you find us?" required />

        <button type="submit">Send</button>
      </form>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ContactForm;
