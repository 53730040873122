import React from 'react';
import './Resources.css';

const ResourceItem = ({ title, description, link, image }) => (
  <div className="resource-item">
    {/* Lazy loading for better performance */}
    <div className="resource-img-wrapper">
      <img src={image} alt={title} className="resource-img" loading="lazy" />
    </div>
    <h3>{title}</h3>
    {/* <p>{description}</p> */}
    {link && (
      <a href={link} target="_blank" rel="noopener noreferrer">
        Learn More
      </a>
    )}
  </div>
);

const Resources = () => {
  const resourcesData = [
    {
      title: 'React Documentation',
      description: 'Official documentation for React.js. A must-read for React developers.',
      link: 'https://reactjs.org/',
      image: '/Resource/item1.webp',
    },
    {
      title: 'Blockchain Basics',
      description: 'Introduction to blockchain technology and its applications.',
      link: 'https://en.wikipedia.org/wiki/Blockchain',
      image: '/Resource/blockchain.webp',
    },
    {
      title: 'Python',
      description: 'The official Python programming language website.',
      link: 'https://www.python.org/',
      image: '/Resource/python.webp',
    },
    {
      title: 'Java Programming',
      description: 'Resources and guides for Java programming.',
      link: 'https://www.oracle.com/java/',
      image: '/Resource/java.webp',
    },
    {
      title: 'Solana Documentation',
      description: 'Comprehensive guide to building on Solana blockchain.',
      link: 'https://solana.com/docs/clients/javascript-reference',
      image: '/Resource/solana.webp',
    },
    {
      title: 'NestJS Documentation',
      description: 'Learn to build efficient, reliable, and scalable server-side applications using NestJS.',
      link: 'https://docs.nestjs.com/',
      image: '/Resource/nest.webp',
    },
    {
      title: 'Amazon Web Services (AWS)',
      description: 'Cloud computing services by Amazon for building, deploying, and managing applications.',
      link: 'https://aws.amazon.com/',
      image: '/Resource/Amazon_Web_Services.webp',
    },
    {
      title: 'Ethereum Basics',
      description: 'Introduction to Ethereum, its blockchain, and smart contract capabilities.',
      link: 'https://ethereum.org/en/',
      image: '/Resource/eterium.webp',
    },
    {
      title: 'Rust Programming Language',
      description: 'Documentation for Rust programming language.',
      link: 'https://www.rust-lang.org/',
      image: '/Resource/rust.webp',
    },
    {
      title: 'Solidity',
      description: 'Learn to develop smart contracts on Ethereum using Solidity.',
      link: 'https://soliditylang.org/',
      image: '/Resource/Solidity.webp',
    },
    {
      title: 'Uniswap',
      description: 'Decentralized trading protocol documentation.',
      link: 'https://uniswap.org/',
      image: '/Resource/item11.png',
    },
    {
      title: 'Web 3.0',
      description: 'Explore the concepts and technologies behind Web 3.0.',
      link: 'https://en.wikipedia.org/wiki/Web3',
      image: '/Resource/web30.webp',
    },
  ];

  return (
    <div id="Resources" className="resources-container">
      <h2 className="DS">Developer Resources</h2>
      <div className="resources-list">
        {resourcesData.map((resource, index) => (
          <ResourceItem key={index} {...resource} />
        ))}
      </div>
    </div>
  );
};

export default Resources;
