import React, { useEffect, useState } from 'react';
import { Routes, Route} from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Resources from './pages/Resources/Resources';
import PlansPricing from './pages/PlansPricing/PlansPricing';
import Products from './pages/Products/Products';
import ReferalLink from './pages/ReferalLink/ReferalLink';
import ContactUs from './pages/ContactUs/ContactUs';
import PrivatePolicy from "./components/Cookie/PrivacyPolicy"
import CompanyBio from "./pages/Bio/CompanyBio"

// Create a Layout component
const Layout = ({ children, currentLanguage, onLanguageSwitch }) => {
  return (
    <div>
      {/* Add a link to scroll to the top */}
      <ScrollLink to="top" smooth="true" duration={500}>
        {/* Scroll to Top */}
      </ScrollLink>

      {/* Header component */}
      <Header currentLanguage={currentLanguage} onLanguageSwitch={onLanguageSwitch} />
     
      {/* Page content */}
      {children}
    </div>
  );
};

// Main RoutesConfig component
const RoutesConfig = () => {
  const [currentLanguage, setCurrentLanguage] = useState('am');

  // Simulate fetching user authentication data
  useEffect(() => {
  
   
  }, []);

  const handleLanguageSwitch = () => {
    setCurrentLanguage((prevLanguage) => (prevLanguage === 'am' ? 'en' : 'am'));
  };

  return (
    <Routes>
      {/* Wrap each page component with the Layout */}
      <Route
        path="/"
        element={
          <Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}>
            <Home currentLanguage={currentLanguage} />
          </Layout>
        }
      />

      <Route path="/resources" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><Resources /></Layout>} />
      <Route path="/plans-pricing" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><PlansPricing /></Layout>} />
      <Route path="/get-in-touch" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><ContactUs /></Layout>} />
      <Route path="/products" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><Products /></Layout>} />
      <Route path="/referal" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><ReferalLink /></Layout>} />
      <Route path="/privacy-policy" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><PrivatePolicy /></Layout>} />
      <Route path="/bio" element={<Layout currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch}><CompanyBio /></Layout>} />

   Bio
    
    </Routes>
  );
};

export default RoutesConfig;
