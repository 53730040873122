import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    console.error('ErrorBoundary caught an error:', error);
    return { hasError: "true", error };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details to the console or send them to an external service
    console.error('Error details:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1 style={styles.title}>Something went wrong!</h1>
          <p style={styles.description}>
            An error occurred while loading the page. Please try refreshing your browser or
            contact support if the problem persists.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    color: '#333',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  description: {
    fontSize: '16px',
    maxWidth: '600px',
    lineHeight: '1.5',
  },
};

export default ErrorBoundary;
