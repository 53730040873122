import React from 'react';
import"./PrivatePolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website, use our services, or interact with us in any other way. By accessing or using our services, you consent to the terms of this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          We collect information that you provide to us directly, such as when you fill out forms, contact us, or make a purchase. This may include your name, email address, phone number, payment details, and other personal information.
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email, phone number.</li>
          <li><strong>Usage Data:</strong> Data about how you interact with our website (e.g., IP address, device type, browser information, etc.).</li>
          <li><strong>Cookies:</strong> We use cookies to track user behavior and preferences. You can manage your cookie preferences through the cookie consent banner.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect for the following purposes:
        </p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To communicate with you, including responding to your inquiries.</li>
          <li>To personalize your experience on our website.</li>
          <li>To process transactions securely.</li>
          <li>To send you marketing materials (with your consent).</li>
        </ul>
      </section>

      <section>
        <h2>Third-Party Services</h2>
        <p>
          We may share your information with trusted third-party service providers who help us operate our website, conduct business, or provide services to you. These third parties are obligated to protect your data and only use it for specific tasks.
        </p>
        <ul>
          <li>Analytics Providers (e.g., Google Analytics, Yandex.Metrika).</li>
          <li>Payment Processors.</li>
          <li>Marketing Platforms (e.g., email marketing services, social media platforms).</li>
        </ul>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>
          You have the following rights regarding your personal data:
        </p>
        <ul>
          <li><strong>Access:</strong> You have the right to access the information we hold about you.</li>
          <li><strong>Correction:</strong> You can request correction of any inaccurate or incomplete data.</li>
          <li><strong>Deletion:</strong> You can request that we delete your data under certain conditions.</li>
          <li><strong>Opt-Out:</strong> You can withdraw your consent for marketing communications at any time.</li>
          <li><strong>Data Portability:</strong> You may request your data in a structured, commonly used format.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the information provided in the "Contact Us" section below.
        </p>
      </section>

      <section>
        <h2>Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to enhance your experience, analyze site usage, and serve targeted advertisements. For more details, please visit our Cookie Policy.
        </p>
      </section>

      <section>
        <h2>Data Retention</h2>
        <p>
          We will retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
      </section>

      <section>
        <h2>Security</h2>
        <p>
          We take reasonable precautions to protect your personal data from unauthorized access, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.
        </p>
      </section>

      <section>
        <h2>International Transfers</h2>
        <p>
          Your data may be transferred to and processed in countries outside the European Economic Area (EEA). We ensure that such transfers are made in compliance with applicable data protection laws.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices, services, or legal requirements. When we make changes, we will post the updated policy on this page and update the "Last Revised" date at the bottom.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or how we handle your personal data, please contact us:
        </p>
        <ul>
          <li>Email: <a href="mailto:codered0101110@gmail.com">codered0101110@gmail.com</a></li>
          <li>Phone: +37496454503</li>
          <li>Address:Armenia Yerevan komitas 24/1</li>
        </ul>
      </section>

      <footer>
        <p>Last Revised: [Insert Date]</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
