import React, { useEffect } from 'react';
import './CompanyBio.css'; // Assuming a CSS file for styling
import { FaLinkedin } from 'react-icons/fa';

const CompanyBio = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once after the first render

  return (
    <section className="company-bio">
      <div className="bio-container">
        <h1 className="bio-title">Welcome to CodeRed</h1>
        <p className="bio-description">
          At CodeRed, we specialize in creating high-quality Web3 and blockchain-powered solutions tailored to meet the unique needs of your business. With over 6 years of experience and more than 50 successful projects, our team ensures efficient and innovative services for every client.
        </p>
        <p className="bio-services">
          Our services include:
          <ul>
            <li>Custom blockchain application development</li>
            <li>Smart contract creation and auditing</li>
            <li>Integration of decentralized technologies with existing platforms</li>
          </ul>
        </p>
        <p className="bio-tech">
          We leverage cutting-edge Web3 technologies such as Ethereum, Binance Smart Chain, and IPFS, alongside JavaScript frameworks (React, Next.js, Vue.js, Node.js, and Nest.js) to deliver exceptional results.
        </p>
        <p className="bio-commitment">
          At CodeRed, we are committed to providing timely and high-quality solutions, ensuring your satisfaction and the success of your projects in the rapidly evolving blockchain space.
        </p>
        <a href="https://www.linkedin.com/company/codered-digital" className="bio-link" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="linkedin-icon" /> LinkedIn
        </a>
      </div>
    </section>
  );
};

export default CompanyBio;
