import React from 'react';
import './services.css'; // Importing styles for the Services component
import langAM from '../../Translations/lang_am.json';
import langEN from '../../Translations/lang_en.json';

const sectionStyle = {};

function Services({ currentLanguage }) {
  const translations = currentLanguage === 'am' ? langAM : langEN;
  return (
    <div id="priceplans">
      <section className="services-section">
        <div className="our-services">
          <h2>Our Services</h2>
          {/* <img src="img/terminal.web" alt="Digital-services"  /> */}
        </div>

        <div className="services-container" style={sectionStyle}>
                {/* Web3/Blockchain Development */}
                <div className="service-item">
            <h3>Web3/Blockchain Development</h3>
            <p>Innovative blockchain solutions, including decentralized applications and smart contracts. Starting at $1,000.</p>
          </div>
          {/* Developer Services */}
          <div className="service-item">
            <h3>{translations.services_Dev}</h3>
            <p>Web development using the latest technologies. Starting at $500.</p>
          </div>

          {/* Design Services */}
          <div className="service-item">
            <h3>Design</h3>
            <p>User-friendly interfaces and visually stunning designs. Starting at $300.</p>
          </div>

          {/* DevOps Services */}
          <div className="service-item">
            <h3>DevOps</h3>
            <p>Streamlining development and deployment processes. Starting at $700.</p>
          </div>

          {/* SEO Services */}
          <div className="service-item">
            <h3>SEO</h3>
            <p>Boost online visibility and organic traffic. Starting at $200.</p>
          </div>

          {/* UI/UX Services */}
          <div className="service-item">
            <h3>UI/UX</h3>
            <p>Creating intuitive and delightful user interfaces. Starting at $400.</p>
          </div>

          {/* Content Writing */}
          <div className="service-item">
            <h3>Content Writing</h3>
            <p>High-quality and engaging content creation. Starting at $150.</p>
          </div>

          {/* Mobile App Development */}
          <div className="service-item">
            <h3>Mobile App Development</h3>
            <p>Custom mobile apps for iOS and Android. Starting at $800.</p>
          </div>

     
          {/* <div className="service-item">
            <h3>Cloud Services</h3>
            <p>Efficient cloud-based solutions for your business. Starting at $600.</p>
          </div> */}

    
        </div>

        {/* Plans & Pricing */}
        <section className="pricing-section">
          <div className="pricing-container">
            <div className="pricing-item">
              <h3>Basic</h3>
              <div className="price">$99/month</div>
              <p>Essential features for small businesses and personal projects.</p>
            </div>

            <div className="pricing-item">
              <h3>Pro</h3>
              <div className="price">$199/month</div>
              <p>Advanced features for growing businesses with increased demands.</p>
            </div>

            <div className="pricing-item">
              <h3>Enterprise</h3>
              <div className="price">Contact Us</div>
              <p>Customized solutions for large enterprises. Get in touch for a quote.</p>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Services;
